@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

@import "../../common/sass/variables";

.menu {
  width: 20%;
  background: #fff;
  background-size: cover;
  background-position: left bottom;
  border-radius: 0 1rem 1rem 0;
  -webkit-box-shadow: 5px 0px 30px -15px rgba(123, 131, 235, 1);
  -moz-box-shadow: 5px 0px 30px -15px rgba(123, 131, 235, 1);
  box-shadow: 5px 0px 30px -15px rgba(123, 131, 235, 1);
  .list-group {
    .indicator {
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 2.5rem;
      background-color: rgba(123, 131, 235, 0.2);
      transition: transform 0.4s;
      border-radius: 0 2rem 2rem 0;
    }
    .list-group-item {
      padding-left: 2.5rem;
      padding-right: 3rem;
      height: 2.5rem;
      border: none;
      border-radius: 0 2rem 2rem 0;
      background: none;
      .ico-menu {
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 80%;
        width: 2rem;
        height: 1.8rem;
        padding: 0.1rem 0;
        transition: all 0.5s;
      }
      .title-menu {
        padding-left: 1rem;
      }
    }
    .list-group-item.active,
    .list-group-item:hover {
      color: $color-primary;
      font-weight: bold;
      background-color: initial;
      .ico-menu {
        transform: scale(1.25) rotate(10deg);
      }
    }
    .list-group-item.menu1 {
      .ico-menu {
        background-size: contain;
      }
    }
    .list-group-item.menu1.active,
    .list-group-item.menu1:hover {
      .ico-menu {
        transform: scale(1.6) rotate(10deg);
      }
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 3em;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

@include media-breakpoint-up(md) {
  .menu {
    height: 100vh;
  }
}
