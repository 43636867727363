@import "../../common/sass/variables";
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

.titre {
  img {
    max-height: 2.25rem;
  }
}
.main-intro {
  .illustration-chronometre {
    width: 15%;
  }
  .content {
    width: 75%;
  }
}
.btn-next {
  text-align: right;
  .btn {
    width: fit-content;
  }
}

@include media-breakpoint-down(md) {
  .main-intro {
    @include bg-encart;
    color: initial;
    .illustration-chronometre {
      width: 50%;
    }
    .content {
      width: 100%;
    }
  }
  .btn-next {
    text-align: center;
    margin-top: 1rem;
    .btn {
      width: 100%;
    }
  }
}
