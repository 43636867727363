.avatar-niveau {
  position: relative;
  width: 80%;
  padding-bottom: 0.25rem;
  img {
    max-width: 40%;
  }
  .niveau {
    background: #fff;
    border-radius: 100%;
    -webkit-box-shadow: 0px 0px 30px -7px rgba(66, 75, 176, 1);
    -moz-box-shadow: 0px 0px 30px -7px rgba(66, 75, 176, 1);
    box-shadow: 0px 0px 30px -7px rgba(66, 75, 176, 1);
  }
}
.avatar-niveau:not(.without-name) {
  margin: 0 auto 0.75rem auto;
  .niveau {
    position: absolute;
    bottom: 0;
    right: 25%;
    line-height: 0.75rem;
    padding: 0.75rem 0.8rem;
  }
}
.avatar-niveau.without-name {
  margin: 0 auto;
  .niveau {
    line-height: 1rem;
    padding: 1rem;
    font-size: 1.5em;
    top: -1.25rem;
    position: relative;
    width: fit-content;
    text-align: center;
    margin: 0 auto;
  }
}
