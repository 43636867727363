@import "../../common/sass/variables";
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

.toolbar-right {
  width: 12rem;
}
.main-recap-quizz {
  .main-entete {
    .titre {
      img {
        max-height: 2.25rem;
      }
    }

    .sep {
      p {
        width: 1px;
        height: 75%;
        margin: 0;
        background-color: $color-primary-light;
        opacity: 0.6;
      }
    }
    .total-xp,
    .total-point {
      padding-left: 1.75rem;
    }

    .total-xp {
      background: url("/images/icone/coin.png") no-repeat top left;
      background-size: contain;
    }
    .total-point {
      background: url("/images/icone/cup.png") no-repeat top left;
      background-size: contain;
    }
  }
  .question.recap {
    .p-result {
      font-size: 1.05em;
    }
    .list-group-item:not(.active-reponse),
    .item-reponse {
      opacity: 0.7;
    }
    .list-group-item.active-reponse-ok,
    .item-reponse.active-reponse-ok {
      opacity: 1;
      background: none;
      border: solid 1px $color-green;
      color: initial;
      .num_reponse{
        border: solid 1px $color-green;
      }
    }
    .list-group-item.active-reponse {
      .order {
        font-style: italic;
        font-size: .9em;
      }
    }
    .list-group-item.active-reponse-ko {
      background: none;
      border: solid 1px $color-pink;
    }
  }
  .question.recap.valid {
    .p-result {
      color: $color-green;
    }
    .list-group-item.active,
    .item-reponse.active {
      opacity: 1;
      background: none;
      border: solid 1px $color-green;
      color: initial;
      .num_reponse {
        border: solid 1px $color-green;
      }
    }
  }
  .question.recap.invalid {
    .p-result {
      color: $color-pink;
    }
    .list-group-item.active:not(.active-reponse-ok),
    .item-reponse.active:not(.active-reponse-ok) {
      opacity: 1;
      background: none;
      border: solid 1px $color-pink;
      color: initial;
      .num_reponse {
        border: solid 1px $color-pink;
      }
    }
  }
  .question.recap {
    .list-group-item:hover, .conteneur-img:hover{
      transform: none;
      background: none;
    }
    .conteneur-img {
      img {
        border-width: 2px !important;
      }
    }
  }

  .astuce {
    .illustration {
      margin: 0 2rem;
      img {
        width: 10rem;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .main-recap-quizz {
    .question.recap {
      .intitule {
        background: $color-primary-light;
        border-radius: 0.75rem;
        color: $color-white1;
        padding: 0.75rem 1rem;
        h5 {
          margin-bottom: 0.25rem;
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  body {
    .main-recap-quizz {
      .main-entete {
        background: url("/images/overlays/overlay-yellow.png") no-repeat !important;
        background-size: cover !important;
        color: initial;
        margin-bottom: 0;
        .color-primary-light {
          color: $color-white1;
        }
        .span-result, .total-xp, .total-point {
          font-size: 1.5rem;
        }
        .total-xp, .total-point {
          padding-left: 2rem;
        }
      }
      .question {
        color: initial;
        @include bg-encart;
        padding: 1.25rem 1.5rem;
        margin-top: .5rem;
        .intitule {
          h5 {
            font-size: 1.25rem;
          }
        }
      }
      .astuce {
        h3 {
          display: flex;
          align-items: center;
          img {
            max-width: 20%;
          }
        }
      }
    }
    .toolbar-right {
      display: none;
    }
  }
}
